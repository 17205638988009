import React, { useContext } from "react";
import Calculator from "../../components/calculators/calculator";
import {
  Section,
  Container,
  PageTitle,
  SectionTitle,
  Equation,
  Note,
  InlineEquation,
  Centered,
} from "../../components/calculators/calculators-layout";
import {
  useFootnotes,
  FootnoteLink,
  Footnotes,
} from "../../components/hooks/use-footnotes";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import MathMLConstants from "../../components/calculators/mathml-constants";
import CALCULATORS_CONSTANTS from "../../components/calculators/constants";
import { Calculators } from ".";

const notes = [
  "Pitzer, K. S., Peiper, J. C., & Busey, R. H. (1984). Thermodynamic properties of aqueous sodium chloride solutions. Journal of Physical and Chemical Reference Data, 13(1), 1-102.",
  "Hamer, W.J. and Wu, Y. (1972). Osmotic coefficients and mean activity coefficients of uni-univalent electrolytes in water at 25 °C. Journal of Physical and Chemical Reference Data 1, 1047–1100."
];

const OsmoticPressureCalculator = () => (
  <Centered>
    <Calculator
      fields={[
        {
          name: "phi_c",
          label: (
            <>
              Enter osmotic coefficient,{" "}
              <InlineEquation math={MathMLConstants.phi_c} /> =
            </>
          ),
          unit: "unitless",
        },
        {
          name: "T",
          label: (
            <>
              Enter temperature, <InlineEquation math={MathMLConstants.T} /> =
            </>
          ),
          unit: "K",
        },
        {
          name: "n",
          label: (
            <>
              Enter number of dissociable species (e.g., 2 for NaCl),{" "}
              <InlineEquation math={MathMLConstants.n} /> =
            </>
          ),
          unit: "unitless",
        },
        {
          name: "C",
          label: (
            <>
              Enter solute concentration,{" "}
              <InlineEquation math={MathMLConstants.C} /> =
            </>
          ),
          unit: <>mol L<sup>-1</sup></>,
        },
      ]}
      outputs={[
        {
          name: "pi",
          label: (
            <span className="is-size-5">
              Osmotic Pressure, <InlineEquation math={MathMLConstants.pi} /> =
            </span>
          ),
          unit: "bar",
          calculate: ({ phi_c, T, n, C }) =>
            phi_c * T * n * C * CALCULATORS_CONSTANTS.R_g / 1000,
        },
      ]}
    />
  </Centered>
);

const OsmoticPressureCalculatorPage = () => {
  const [footnotes] = useFootnotes(notes);

  return (
    <Layout>
      <Seo
        title="Osmotic Pressure Calculator"
        description="Osmotic pressure is the pressure that would need to be applied to
            prohibit a pure solvent from passing into a given solution by
            osmosis."
      />
      <Section>
        <Container>
          <PageTitle>Osmotic Pressure Calculator</PageTitle>
        </Container>
        <Container>
          <SectionTitle>
            Osmotic Pressure, <InlineEquation math={MathMLConstants.pi} />
          </SectionTitle>
          <p className="mb-3">
            Osmotic pressure is the pressure that would need to be applied to
            prohibit a pure solvent from passing into a given solution by
            osmosis. The osmotic pressure of a solution depends on the
            concentration of dissolved solute particles and can be calculated by
            the modified van’t Hoff equation:
          </p>
          <Equation math={MathMLConstants.osmoticPressure} />
        </Container>
        <Container>
          <OsmoticPressureCalculator />
          <Note>
            <InlineEquation math={MathMLConstants.phi_c} /> depends on solute
            type and concentration solution and can be found for NaCl solutions in references{' '}
            <FootnoteLink footnotes={footnotes} start={1} end={1} /> and <FootnoteLink footnotes={footnotes} start={2} end={2} />.{" "}
            <InlineEquation math={MathMLConstants.R_g} /> is the ideal gas
            constant (L bar K<sup>-1</sup> mol <sup>-1</sup>
            ). The OMD used the OLI activity coefficient model (OLI Systems,
            Morris Plains, NJ) to calculate the osmotic pressure of NaCl
            concentrations in studies initially uploaded to the database.
          </Note>
        </Container>
      </Section>
      <Section>
        <Container>
          <Footnotes footnotes={footnotes} />
        </Container>
      </Section>
      <hr />
      <Calculators />
    </Layout>
  );
};
export default OsmoticPressureCalculatorPage;
